import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function PublicRoute() {
  const token = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("expirationTime");
  const isLoggedIn =
    token && expirationTime && Date.now() < parseInt(expirationTime, 10);

  return !isLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
}

export default PublicRoute;
