import instance from "./axios";

export const configAuth = () => {
  return {
    Authorization: `${localStorage.getItem("token")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    // "x-header-deviceid": localStorage.getItem("deviceId"),
  };
};

export const config = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const apiPost = (url, body, isAuth = true, isIconUpdate = false) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  if (isIconUpdate) {
    delete headers["Content-Type"];
  }

  const response = instance
    .post(url, body, {
      headers: headers,
    })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response;
};

export const apiPut = (url, body, isAuth = true, isIconUpdate = false) => {
  let headers;

  if (isAuth === false) {
    headers = config();
  } else {
    headers = configAuth();
  }

  if (isIconUpdate) {
    delete headers["Content-Type"];
  }

  const response = instance
    .put(url, body, {
      headers: headers,
    })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response;
};

export const apiGet = (url, data, isAuth = true) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  const response = instance
    .get(url, { params: data, headers: headers })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response;
};


export const apiDeletes = (url, isAuth = true) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  const response = instance
    .delete(url, {
      headers: headers,
    })
    .then((response) => response)
    .catch((err) => {
      return err;
    });
  return response;
};
