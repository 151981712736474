import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ requiredRole }) => {
  const token = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("expirationTime");
  const userRole = localStorage.getItem("role"); // Simpan role pengguna di localStorage

  // Cek token dan waktu kedaluwarsa
  if (!token || (expirationTime && Date.now() > parseInt(expirationTime, 10))) {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }

  // Cek role jika `requiredRole` diberikan
  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
