import axios from "axios"
import { API_URL } from "./apiUrl";

export const instance = axios.create({
  // baseURL: 'https://staging-backend.superdigitalapps.my.id/api'
  
  baseURL: API_URL.API_HOST
});

export default instance;
