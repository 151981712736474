import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./routes/publicRoute";
import PrivateRoute from "./routes/privateRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import { HeaderTitleProvider } from "./components/headerTitle";
import ErrorBoundary from "./components/error-boundary";
import MainLayout from "./layout";

const Dashboard = lazy(() => import("./pages/dashbord/index"));
const DataNasional = lazy(() => import("./pages/dashbord-gizi/data-nasional"));
const DataGizi = lazy(() => import("./pages/dashbord-gizi/data-gizi"));
const PerkembanganGizi = lazy(() => import("./pages/dashbord-gizi/perkembangan-gizi"));
const AngkaGizi = lazy(() => import("./pages/dashbord-gizi/angka-gizi"));
const Login = lazy(() => import("./pages/login"));
const ControlUser = lazy(() => import("./pages/control-user"));
const FiturTransaksi = lazy(() => import("./pages/fitur-transaksi"));
const FiturDistributor = lazy(() => import("./pages/fitur-distributor"));
const Product = lazy(() => import("./pages/product"));
const MainCategory = lazy(() => import("./pages/category/kategori-utama"));
const SubCategory = lazy(() => import("./pages/category/sub-kategori"));
const SpecificCat = lazy(() => import("./pages/category/spesifik-kategori"));
const FakturNonSubsidi = lazy(() => import("./pages/invoice/non-subsidi"));
const FakturSubsidi = lazy(() => import("./pages/invoice/subsidi"));
const Tariff = lazy(() => import("./pages/tarif"));
const CompanyProfile = lazy(() => import("./pages/compro"));
const Events = lazy(() => import("./pages/event"));
const MakananOlahan = lazy(() => import("./pages/pangan-olahan/makanan-olahan"));
const BahanOlahan = lazy(() => import("./pages/pangan-olahan/bahan-olahan"));
const TinjauPesanan = lazy(() => import("./pages/tinjau-pesanan"));
const HapusAkun = lazy(() => import("./pages/hapus-akun"));
const JudulUtama = lazy(() => import("./pages/pusat-bantuan/judul-utama"));
const SubJudul = lazy(() => import("./pages/pusat-bantuan/sub-judul"));
const SpesifikJudul = lazy(() => import("./pages/pusat-bantuan/spesifik"));
const Profile = lazy(() => import("./pages/Profile"));
const School = lazy(() => import("./pages/school"));
const DetailKonsumen = lazy(() => import("./pages/pusat-bantuan/spesifik/Detail/detail-konsumen"))
const DetailVendor = lazy(() => import("./pages/pusat-bantuan/spesifik/Detail/detail-vendor"))
const DetailDistributor = lazy(() => import("./pages/pusat-bantuan/spesifik/Detail/detail-distributor"))
const DetailProdusen = lazy(() => import("./pages/pusat-bantuan/spesifik/Detail/detail-produsen"));
const DetailSupplier = lazy(() => import("./pages/pusat-bantuan/spesifik/Detail/detail-supplier"));
const Pesanan = lazy(() => import("./pages/pesanan/pesanan"));
const Transaksi = lazy(() => import("./pages/transaksi/transaksi"));
const AdminDashboard = lazy(() => import("./pages/adminDashboard"));
const TransaksiPrint = lazy(() => import("./pages/transaksi/print"));
const NonSubsidyPrint = lazy(() => import("./pages/invoice/non-subsidi/print"));
const SubsidyPrint = lazy(() => import("./pages/invoice/subsidi/print"))

function App() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLogged(token !== null);
  }, []);

  return (
    <HeaderTitleProvider>
      <BrowserRouter>
        <div className="relative font-work-sans">
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/login" element={<Login logged={isLogged} />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/help-center/specific-help-center/manufacturer/:id" element={<DetailProdusen />} />
                <Route path="/nutrition-dashboard/national-data" element={<DataNasional />} />
                <Route path="/nutrition-dashboard/nutrition-data" element={<DataGizi />} />
                <Route path="/nutrition-dashboard/nutritional-development" element={<PerkembanganGizi />} />
                <Route path="/nutrition-dashboard/nutrition-figures" element={<AngkaGizi />} />
                <Route path="/fitur-transaksi" element={<FiturTransaksi />} />
                <Route path="/control-user" element={<ControlUser />} />
                <Route path="/distributor-feature" element={<FiturDistributor />} />
                <Route path="/product" element={<Product />} />
                <Route path="/main-category" element={<MainCategory />} />
                <Route path="/sub-category" element={<SubCategory />} />
                <Route path="/specific-category" element={<SpecificCat />} />
                <Route path="/invoice/non-subsidy" element={<FakturNonSubsidi />} />
                <Route path="/invoice/subsidy" element={<FakturSubsidi />} />
                <Route path="/schools" element={<School />} />
                <Route path="/tariff" element={<Tariff />} />
                <Route path="/company-profile/mbg" element={<CompanyProfile />} />
                <Route path="/events" element={<Events />} />
                <Route path="/processed-food/processed-food" element={<MakananOlahan />} />
                <Route path="/processed-food/processed-ingredienty" element={<BahanOlahan />} />
                <Route path="/incomplete-order" element={<TinjauPesanan />} />
                <Route path="/delete-account" element={<HapusAkun />} />
                <Route path="/help-center/main-title" element={<JudulUtama />} />
                <Route path="/help-center/subtitle" element={<SubJudul />} />
                <Route path="/help-center/specific-title" element={<SpesifikJudul />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/help-center/specific-help-center/consumer/:id" element={<DetailKonsumen />} />
                <Route path="/help-center/specific-help-center/vendors/:id" element={<DetailVendor />} />
                <Route path="/help-center/specific-help-center/distributors/:id" element={<DetailDistributor />} />
                <Route path="/help-center/specific-help-center/suppliers/:id" element={<DetailSupplier />} />
                <Route path="/order-list" element={<Pesanan />} />
                <Route path="/transaction-list" element={<Transaksi />} />
                <Route path="/dashboard-admin" element={<AdminDashboard />} />
              </Route>
              <Route path="/transaction-print" element={<TransaksiPrint />} />
              <Route path="/invoice/non-subsidy-print" element={<NonSubsidyPrint />} />
              <Route path="/invoice/subsidy-print" element={<SubsidyPrint />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </HeaderTitleProvider>
  );
}

export default App;