import React, { useState, useEffect, useContext } from "react";
import logo from "../assets/images/logo.png";
import {
  IconGizi,
  IconDashboard,
  IconUser,
  IconTransaksi,
  IconKurir,
  IconCategory,
  IconCompro,
  IconDeleteAccount,
  IconEvents,
  IconHelpCenter,
  IconInvoice,
  IconPanganOlahan,
  IconProduct,
  IconSchool,
  IconTarif,
  IconTinjauPesanan
} from "../assets/icons/icon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HeaderTitleContext } from "./headerTitle";
import { SlArrowRight } from "react-icons/sl";
import { apiGet } from "../api/apiFunction"
import loadingGif from "../assets/videos/animationLoading.gif";

const sidebar = [
  {
    title: "Dashboard",
    icon: <IconDashboard className="w-6 h-6" />,
    path: "/",
  },
  {
    title: "Dashboard Gizi",
    icon: <IconGizi className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: " - Data Nasional",
        path: "/dashboardgizi/datanasional",
      },
      {
        title: " - Data Gizi Provinsi",
        path: "/dashboardgizi/datagizi",
      },
      {
        title: " - Perkembangan Gizi",
        path: "/dashboardgizi/perkembangangizi",
      },
      {
        title: " - Angka Kecukupan Gizi",
        path: "/dashboardgizi/angkagizi",
      },
    ],
  },
  {
    title: "Pesanan",
    icon: <IconTransaksi className="w-6 h-6" />,
    path: "/pesanan"
  },
  {
    title: "Transaksi",
    icon: <IconTransaksi className="w-6 h-6" />,
    path: "/transaksi/transaksi"
  },
  {
    title: "Fitur Kurir",
    icon: <IconKurir className="w-6 h-6" />,
    path: "/fitur-distributor",
  },
  {
    title: "Kontrol Pengguna",
    icon: <IconUser className="w-6 h-6" />,
    path: "/kontrol-pengguna",
  },
  {
    title: "Produk",
    icon: <IconProduct className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: " - Produk",
        path: "/produk/1",
      },
    ],
  },
  {
    title: "Faktur",
    icon: <IconInvoice className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Subsidi",
        path: "/faktur/subsidi",
      },
      {
        title: "- Non-subsidi",
        path: "/faktur/non-subsidi",
      },
    ],
  },
  {
    title: "Kategori",
    icon: <IconCategory className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Kategori Utama",
        path: "/kategori-utama",
      },
      {
        title: "- Sub Kategori",
        path: "/sub-kategori",
      },
      {
        title: "- Spesifik Kategori",
        path: "/spesifik-kategori",
      },
    ],
  },
  { title: "Acara", icon: <IconEvents className="w-6 h-6" />, path: "/acara" },
  {
    title: "Sekolah",
    icon: <IconSchool className="w-6 h-6" />,
    path: "/sekolah",
  },
  {
    title: "Tarif",
    icon: <IconTarif className="w-6 h-6" />,
    path: "/tarif",
  },
  {
    title: "Profil Perusahaan",
    icon: <IconCompro className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- MBG",
        path: "/profil-perusahaan/mbg",
      },
    ],
  },
  {
    title: "Pangan Olahan",
    icon: <IconPanganOlahan className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Makanan Olahan",
        path: "/pangan-olahan/makanan-olahan",
      },
      {
        title: "- Bahan Olahan",
        path: "/pangan-olahan/bahan-olahan",
      },
    ],
  },
  {
    title: "Tinjau Pesanan",
    icon: <IconTinjauPesanan className="w-6 h-5" />,
    path: "/tinjau-pesanan",
  },
  {
    title: "Pengajuan Hapus Akun",
    icon: <IconDeleteAccount className="w-6 h-5" />,
    path: "/hapus-akun",
  },
  {
    title: "Pusat Bantuan",
    icon: <IconHelpCenter className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Judul Utama",
        path: "/pusat-bantuan/judul-utama",
      },
      {
        title: "- Sub Judul",
        path: "/pusat-bantuan/sub-judul",
      },
      {
        title: "- Spesifik",
        path: "/pusat-bantuan/spesifik",
      },
    ],
  },
];

function Sidebar() {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const { setTitle } = useContext(HeaderTitleContext);
  const [sidebarList, setSidebarList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    // Ambil role dari localStorage
    const roleFromLocalStorage = localStorage.getItem("role");
    if (roleFromLocalStorage) {
      setUserRole(roleFromLocalStorage);
    }
    const getSidebarList = async () => {
      setIsLoading(true)
      try {
        const response = await apiGet("/panel/get-data-hakAkses", null, true);
        if (response.status === 200) {
          const data = response.data.data;

          // Filter menu berdasarkan role dan active: true
          const filteredSidebar = data.filter((menu) =>
            menu.role.some(
              (r) =>
                r.active === true &&
                r.roleId?.role === roleFromLocalStorage // Role cocok dengan localStorage
            )
          );
          console.log(filteredSidebar)
          setSidebarList(filteredSidebar); // Simpan menu yang sudah difilter
        } else {
          console.error("Gagal mendapatkan data sidebar");
        }
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data", error);
      } finally {
        setIsLoading(false)
      }
    };

    getSidebarList();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentMenuIndex = sidebarList.findIndex(menu =>
      currentPath === menu.url ||
      (menu.submenu && menu.submenu.some(sub => sub.url === currentPath))
    );
    setActiveIndex(currentMenuIndex);

    const currentSubmenuIndex = sidebarList.findIndex(menu =>
      menu.submenu && menu.submenu.some(sub => sub.url === currentPath)
    );
    setExpandedMenu(currentSubmenuIndex);

    const activeMenu = sidebarList.find(menu =>
      menu.url === currentPath ||
      (menu.submenu && menu.submenu.some(sub => sub.url === currentPath))
    );
    if (activeMenu) {
      setTitle(activeMenu.name);
    }
  }, [location.pathname, setTitle, sidebarList]);

  const handleMenuClick = (index, title) => {
    if (sidebarList[index].submenu) {
      if (expandedMenu === index) {
        setExpandedMenu(null);
      } else {
        setExpandedMenu(index);
      }
    } else {
      setActiveIndex(index);
      setExpandedMenu(null);
    }
    setTitle(title);
  };

  const handleSubmenuClick = (submenuItem) => {
    setTitle(submenuItem.name);
  };

  const handleDashboard = () => {
    navigate("/")
  }

  return (
    <div className="lg:w-[280px] w-[60px] h-screen border-r border-[#C7CED2] bg-[#F7F7F7] fixed top-0 left-0">
      <div className="flex justify-center cursor-pointer items-center pt-6" onClick={handleDashboard}>
        <img src={logo} alt="logo" className="w-[82px] h-[40px]" />
      </div>
      {isLoading ? (
        <div className="absolute inset-0 flex justify-center items-center z-50">
          <img src={loadingGif} alt="Loading Gif" />
        </div>
      ) : (
        <div className="custom-scrollbar overflow-y-auto">
          <ul className="mt-4 px-2">
            {sidebarList.map((menu, index) => (
              <li key={index}>
                <Link
                  to={menu.url}
                  className={`flex items-center justify-between w-full h-[50px] px-3 rounded-lg gap-2 my-2 text-[14px] font-normal leading-4 cursor-pointer transition-colors duration-200 no-underline ${expandedMenu === index ? "lg:bg-[#F15A241A] text-orange-500" : activeIndex === index ? "lg:bg-[#F15A241A] text-orange-500" : "text-[#696B6D] lg:hover:bg-[#F15A241A] hover:text-orange-500"
                    }`}
                  onClick={() => handleMenuClick(index, menu.name)}
                >
                  <div className={`flex items-center ${menu.submenu && menu.submenu.length > 0 ? "w-[224px]" : "w-full"} gap-2`}>
                    <div className="w-6 h-6 hover:text-orange-500" dangerouslySetInnerHTML={{ __html: menu.icon }} />
                    <span className="hidden lg:block">{menu.name}</span>
                  </div>
                  {menu.submenu && menu.submenu.length > 0 && (
                    <SlArrowRight
                      className={`transition-transform duration-300 ${expandedMenu === index ? "rotate-90" : ""}`}
                    />
                  )}
                </Link>
                {expandedMenu === index && menu.submenu && menu.submenu.length > 0 && (
                  <ul className="ml-2">
                    {menu.submenu.map((submenuItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={submenuItem.url}
                          className={`flex no-underline items-center w-full h-[36px] p-2 rounded-lg gap-2 my-1 text-[14px] font-normal cursor-pointer transition-colors duration-200 ${location.pathname === submenuItem.url ? "text-orange-500" : "text-[#696B6D] hover:text-orange-500"
                            }`}
                          onClick={() => handleSubmenuClick(submenuItem)}
                        >
                          - {submenuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
