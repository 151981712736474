import React, { createContext, useState } from "react";

export const HeaderTitleContext = createContext();

export const HeaderTitleProvider = ({ children }) => {
  const [title, setTitle] = useState("Fitur Transaksi");

  return (
    <HeaderTitleContext.Provider value={{ title, setTitle }}>
      {children}
    </HeaderTitleContext.Provider>
  );
};
